
function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function toggleTheme() {
  if (document.documentElement.getAttribute('data-theme') === 'dark') {
    document.documentElement.setAttribute('data-theme', 'light');
    setCookie('theme', 'light', 300); // setting the cookie to expire in 365 days
  } else {
    document.documentElement.setAttribute('data-theme', 'dark');
    setCookie('theme', 'dark', 300);
  }
}


document.addEventListener("DOMContentLoaded", function() {

//  $.ajaxSetup({
//    headers: {
//      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
//    }
//  });
//  // Handle the collapse toggle
//  $('[data-toggle="collapse"]').click(function() {
//    var target = $(this).data('target');
//    $(target).collapse('toggle');
//  });

  const toggleButton = document.getElementById('theme-toggler');

  toggleButton.addEventListener('click', toggleTheme);

  var selector = toggleButton;
  var nearThreshold = 100; // Change this to whatever "near" means for you

  // Function to check if the mouse cursor is near a given element
  function nearElement(e, element, threshold) {
      var rect = element.getBoundingClientRect();
      var dx = e.clientX - (rect.left + rect.width / 2);
      var dy = e.clientY - (rect.top + rect.height / 2);
      return dx * dx + dy * dy <= threshold * threshold;
  }

  // Initially, show and animate the selector
  // After 15 seconds, hide the selector
  selector.classList.add('show');
  selector.classList.add('animate');
  var timeout  = setTimeout(function() {
      selector.classList.remove('show');
      selector.classList.remove('animate');
      window.addEventListener('mousemove', function(e) {
        if (nearElement(e, selector, nearThreshold)) {
          // Near the selector, show it
          selector.classList.add('show');
          selector.classList.add('animate');
        } else {
          // Not near the selector, hide it
          selector.classList.remove('show');
          selector.classList.remove('animate');
        }
      }, false);
  }, 15000);
    var copyit = document.getElementsByClassName('copy-button');
    for(let i=0; i < copyit.length; i++){
      if(copyit[i]){
        copyit[i].addEventListener('click', function() {
          var textField = document.getElementById(this.attributes["dataid"].value);
          textField.disabled = false;
          textField.select();
          textField.setSelectionRange(0, 99999);  // For mobile devices
          document.execCommand('copy');
          textField.disabled = true;
        });
       }
    }
});



