const images = require.context('../images', true)

//// Import images and fonts
//function importAll(r) {
//  r.keys().forEach(r);
//}
//
//importAll(require.context('../images', false, /\.(svg)$/));
//importAll(require.context('../fonts', false, /\.(woff|woff2|eot|ttf|otf)$/));
import jQuery from 'jquery';

if (typeof window !== 'undefined') {
	window.jQuery = window.$ = jQuery;
}

// Your custom popover function


(function( $ ) {
$.fn.popover = function(options) {
    this.each(function() {
        let popoverInstance = new bootstrap.Popover(this, options);
        if (options.trigger === 'hover') {
            $(this).mouseenter(function() {
                popoverInstance.show();
            });
            $(this).mouseleave(function() {
                popoverInstance.hide();
            });
        } else {
            popoverInstance.show();
        }
    });
};})(jQuery);


// Check if jQuery is loaded
if (window.jQuery) {
  // Dynamically import 'chosen-js/chosen.jquery'
  import('chosen-js/chosen.jquery').then((chosen) => {
//     console.log("Chosen loaded:", chosen);
      const customEvent = new CustomEvent("chosenLoaded", { detail: {msg: "hello"} });

        document.dispatchEvent(customEvent);

    // Initialize or use 'chosen' here
  }).catch((error) => {
    console.log('An error occurred while loading the component:', error);
  });

} else {
  console.log('jQuery is not loaded');
}
import Util from 'bootstrap/js/dist/util';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Toast from 'bootstrap/js/dist/toast';
import Modal from 'bootstrap/js/dist/modal';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Collapse from 'bootstrap/js/dist/collapse';

// Attach them to the window object
window.bootstrap = {};
window.bootstrap.Tooltip = Tooltip;
window.bootstrap.Util = Util;
window.bootstrap.Dropdown = Dropdown;
window.bootstrap.Toast = Toast;
window.bootstrap.Modal = Modal;
window.bootstrap.Collapse = Collapse;
$.fn.modal = window.bootstrap.Modal.jQueryInterface
$.fn.modal.Constructor = window.bootstrap.Modal
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'jquery-ui';
import bootbox from 'bootbox';

if (typeof window !== 'undefined') {
	window.bootbox = bootbox;
}
import '../stylesheets/additions.css';
import '../stylesheets/support.css';

// Import JavaScript libraries and modules

//document.addEventListener("DOMContentLoaded", () => {
//});
import '../application.js';

